/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { Formik } from 'formik';
import { RxDotFilled } from 'react-icons/rx';

const Step3Form = ({ formData, onSubmit, handleBack }) => (
	<Formik initialValues={formData} onSubmit={onSubmit}>
		{({ submitForm }) => (
			<div>
				<h2 className="mb-6 text-2xl font-bold">Review your application</h2>
				<div className="space-y-8">
					<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
						<div>
							<label className="mb-1 block font-medium">First Name</label>
							<p className="text-gray-600">{formData.firstName}</p>
						</div>
						<div>
							<label className="mb-1 block font-medium">Last name</label>
							<p className="text-gray-600">{formData.lastName}</p>
						</div>
						<div>
							<label className="mb-1 block font-medium">Email</label>
							<p className="text-gray-600">{formData.email}</p>
						</div>
						<div>
							<label className="mb-1 block font-medium">Mobile</label>
							<p className="text-gray-600">{formData.mobile}</p>
						</div>
						<div>
							<label className="mb-1 block font-medium">LinkedIn URL</label>
							<p className="text-gray-600">{formData.linkedinUrl}</p>
						</div>
						<div>
							<label className="mb-1 block font-medium">Website</label>
							<p className="text-gray-600">{formData.websiteUrl}</p>
						</div>
					</div>

					<div>
						<label className="mb-1 block font-semibold">Resume/CV*</label>
						<p className="font-semibold text-[#1098FF] underline">{formData.resume?.name}</p>
					</div>

					<div className="space-y-8">
						{formData.dynamicQuestions?.map((item, index) => (
							<div key={index}>
								<label className="mb-1 flex items-center gap-1 font-medium">
									<RxDotFilled />
									{item.question}
								</label>
								<input
									type="text"
									disabled
									className="w-full rounded-lg border border-[#EFF1F3] text-gray-600"
									value={item.answer}
								/>
							</div>
						))}
					</div>

					<div className="mt-12 flex justify-between">
						<button
							type="button"
							onClick={handleBack}
							className="rounded-lg border border-black px-6 py-3"
						>
							Back
						</button>
						<button
							type="button"
							onClick={submitForm}
							className="rounded-lg bg-black px-6 py-3 text-white"
						>
							Submit Application
						</button>
					</div>

					<p className="mt-16 px-20 text-center text-xs font-bold text-gray-500">
						By submitting this application, you confirm the right to use and share the information
						provided with all concerns & partners of{' '}
						<span className="text-[#1098FF]">EWN Bangladesh</span>.
					</p>
				</div>
			</div>
		)}
	</Formik>
);

export default Step3Form;
