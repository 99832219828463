import poweredby from '../../assets/svgs/poweredby.svg';

const PoweredBy = () => {
	return (
		<div className="flex">
			<div className="mx-auto my-20 flex items-baseline gap-2">
				<span>Powered by - </span>
				<img src={poweredby} alt="poweredby" className="w-28" />
			</div>
		</div>
	);
};

export default PoweredBy;
