import * as Yup from 'yup';

export const Step1Schema = Yup.object().shape({
	firstName: Yup.string().required('First name is required'),
	lastName: Yup.string().required('Last name is required'),
	email: Yup.string().email('Invalid email').required('Email is required'),
	mobile: Yup.string().required('Mobile number is required'),
	linkedinUrl: Yup.string().url('Invalid URL').required('LinkedIn URL is required'),
	websiteUrl: Yup.string().url('Invalid URL'),
	resume: Yup.mixed().required('Resume is required'),
});

export const createStep2Schema = (questions) =>
	Yup.object().shape(
		(questions || []).reduce((acc, _, index) => {
			acc[`question_${index}`] = Yup.string().required('This field is required');
			return acc;
		}, {})
	);
