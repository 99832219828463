/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { getJobs } from '../../apis/jobs/jobApis';
import Avatar from '../../assets/images/men.png';
import Star from '../../assets/svgs/StartIcon';
import { ApplicantsBanner } from '../../components/molecules/ApplicantsBanner';
import JobsCard from '../../components/molecules/JobsCard';
import StatisticsCards from '../../components/organisms/StatisticsCards';
import { TalentPoolSlider } from '../../components/templates/TalentPoolSlider';
import { DropDownData } from '../../data/DropDowndata';

const NewDashboardPage = ({
	jobType = '',
	handleConfirmationModal,
	deleteID,
	handleShareJobModal,
	handleCloseJob,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState('Last 30 days');
	const [filterParams, setFilterParams] = useState({
		sort: 'createdAt',
		location: '',
	});

	const { sort, location } = filterParams;
	// Queries
	const { data: jobs } = useQuery({
		queryKey: ['jobs', jobType, sort, location],
		queryFn: () => getJobs(jobType, sort, location),
	});
	// Queries

	const buttonText = 'Start hiring';
	const heading = 'Get your hiring process off to a flying start with HrClerks.';
	const para =
		"Post jobs, get candidates and manage applicants all in one place. Let's get started.";

	const options = ['Last 30 days', 'Last 7 days', 'Last 24 hours'];

	const topApplicants = [
		{
			name: 'Devon Lane',
			email: 'icadahli@gmail.com',
			rating: 4.3,
			avatar: Avatar,
		},
		{
			name: 'Henry Smith',
			email: 'henry@hellomail.com',
			rating: 5.0,
			avatar: Avatar,
		},
		{
			name: 'Jacob Jones',
			email: 'joie@gmail.com',
			rating: 3.5,
			avatar: Avatar,
		},
		{
			name: 'Savannah Nguyen',
			email: 'danten@mail.ru',
			rating: 4.5,
			avatar: Avatar,
		},
	];

	const recentActivity = [
		{
			person: 'Devon Lane',
			action: 'applied for Software Engineer',
			time: '10 mins ago',
		},
		{
			person: 'Jacob Jones',
			action: 'applied for Software Engineer',
			time: '10 mins ago',
		},
		{
			person: 'Henry Smith',
			action: 'was labeled Not-fit for Frontend Developer',
			time: '10 mins ago',
		},
		{
			person: 'Jacob Jones',
			action: 'was rated 4.5',
			time: '2 days ago',
		},
		{
			person: 'Jacob Jones',
			action: 'applied for Software Engineer',
			time: 'Nov 02, 2024',
		},
	];

	return (
		<div className="mx-1.5 my-6 min-h-[calc(100vh-180px)] rounded-xl bg-white p-3 pb-0 pt-5 drop-shadow-xl min-[500px]:mx-6 min-[500px]:mt-[32.5px] sm:p-6 md:pb-20">
			<ApplicantsBanner buttonText={buttonText} heading={heading} para={para} />

			{/* Quick Stats */}
			<div className="mx-auto py-8">
				<div className="mb-6 flex items-center justify-between px-6">
					<h2 className="text-xl font-semibold text-gray-900">Quick Stats</h2>
					<div className="relative">
						<button
							onClick={() => setIsOpen(!isOpen)}
							className="flex items-center gap-2 rounded-lg border border-[#A6AFBD] bg-white px-4 py-2 font-semibold text-[#1098FF]"
						>
							{selected}
							<svg
								className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M19 9l-7 7-7-7"
								/>
							</svg>
						</button>

						{isOpen && (
							<div className="absolute right-0 mt-2 w-48 rounded-lg border border-gray-200 bg-white p-2 font-semibold text-[#1098FF] shadow-xl">
								{options.map((option) => (
									<button
										key={option}
										onClick={() => {
											setSelected(option);
											setIsOpen(false);
										}}
										className={`w-full rounded-lg px-4 py-2 text-left hover:bg-gray-200 hover:text-gray-400 ${
											selected === option ? 'text-blue-400' : 'text-gray-300'
										}`}
									>
										{option}
									</button>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<StatisticsCards />

			<div className="mb-6 mt-10 flex items-center justify-between px-6">
				<h2 className="text-xl font-semibold text-gray-900">Active Jobs</h2>
				<Link
					to="/jobs"
					className="text-medium rounded-md border border-gray-300 px-2 py-1 font-semibold text-[#1098FF] hover:text-blue-600"
				>
					View All
				</Link>
			</div>

			<TalentPoolSlider>
				{jobs?.data?.jobs?.slice(0, 5)?.map((job) => (
					<JobsCard
						key={job?.id}
						dropIcon={<HiDotsVertical />}
						DropDownData={DropDownData}
						data={[job]}
						handleConfirmationModal={handleConfirmationModal}
						handleShareJobModal={handleShareJobModal}
						handleCloseJob={handleCloseJob}
						fromDashboard
					/>
				))}
			</TalentPoolSlider>

			<div className="mt-6 grid gap-6 md:grid-cols-2">
				{/* Top Applicants Card */}
				<div className="bg-card text-card-foreground rounded-lg border bg-[#F6F9FC] p-6 shadow-md">
					<h2 className="mb-6 text-2xl font-bold">Top Applicants</h2>
					<div className="space-y-0">
						{topApplicants.map((applicant, index) => (
							<div
								key={index}
								className="flex cursor-pointer items-center justify-between border-b border-[#D3D6DF] bg-white p-4 hover:border-none hover:bg-[#F4FAFF] hover:shadow-md"
							>
								<div className="flex items-center gap-3">
									<img src={applicant.avatar} alt="" className="h-10 w-10 rounded-full" />
									<div className="space-y-1">
										<h3 className="mr-3 text-base font-medium">{applicant.name}</h3>
									</div>
									<p className="text-sm text-gray-500">{applicant.email}</p>
								</div>
								<div className="flex gap-1">
									<Star />
									<span className="text-sm">{applicant.rating}/5</span>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* Recent Activity Card */}
				<div className="bg-card text-card-foreground rounded-lg border bg-[#F6F9FC] p-6 shadow-md">
					<h2 className="mb-6 text-2xl font-bold">Recent Activity</h2>
					<div className="space-y-0">
						{recentActivity.map((activity, index) => (
							<div
								key={index}
								className="flex cursor-pointer border-b border-[#D3D6DF] bg-white px-4 py-5 hover:border-none hover:bg-[#F4FAFF] hover:shadow-md"
							>
								<div className="flex-1 pr-4">
									<p className="break-words text-base">
										<span className="mr-1 font-medium">{activity.person}</span>{' '}
										<span className="text-gray-400">{activity.action}</span>
									</p>
								</div>
								<span className="flex-shrink-0 text-sm text-gray-500">{activity.time}</span>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewDashboardPage;
