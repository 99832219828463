import { lazy } from 'react';

import { ADMIN, ASSISTANT, MANAGER } from '../constants/roles';
// import HomePage from '../pages/_home/Home';
import LoginPage from '../pages/login-page/LoginPage';
import NewDashboardPage from '../pages/NewDashboardPage/NewDashboardPage';
import PublicJobDetailsCompanyPage from '../pages/PublicJobDetailsCompanyPage';

// const ContactSalesTeamPage = lazy(() => import('../pages/_contactSalesTeam/ContactSalesTeamPage'));
const ForgetPasswordEmailPage = lazy(() => import('../pages/ForgetPasswordEmailPage'));
const ForgotPasswordOTPPage = lazy(() =>
	import('../pages/forgotPasswordOTP/ForgotPasswordOTPPage')
);
const NewPasswordPage = lazy(() => import('../pages/NewPasswordPage'));
const AddApplicantPage = lazy(() => import('../pages/add-applicant-page/AddApplicantPage'));
const ChangePassword = lazy(() => import('../components/templates/ChangePassword'));
const ChangePasswordPage = lazy(() => import('../pages/change-password-page/ChangePasswordPage'));
// const CompanyProfile = lazy(() => import('../pages/company-profile-page/CompanyProfile'));
const CreateAPool = lazy(() => import('../pages/create-a-pool-page/CreateAPoolPage'));
const CreateJobPost = lazy(() => import('../components/organisms/CreateJobPost'));
// const DashboardPage = lazy(() => import('../pages/Dashboard/Dashboard'));
// const EditApplicantPage = lazy(() => import('../pages/edit-applicant-page/EditApplicantPage'));
const EditCompanyInfo = lazy(() => import('../pages/edit-company-info-page/EditCompanyInfo'));
const EditJobDetails = lazy(() => import('../pages/job-details/JobDetails'));
const ForgotPasswordPage = lazy(() => import('../pages/forgot-password-page/ForgotPasswordPage'));
const InvitationSignUpPage = lazy(() =>
	import('../pages/invitation-signup-page/InvitationSignUpPage')
);
const JobApplicantDetailsFromAllJob = lazy(() =>
	import('../pages/JobApplicantDetailsPage/JobApplicantDetailsFromAllJob')
);
const JobApplicantDetailsFromPool = lazy(() =>
	import('../pages/JobApplicantDetailsPage/JobApplicantDetailsFromPool')
);
const JobApplicantDetailsPage = lazy(() =>
	import('../pages/JobApplicantDetailsPage/JobApplicantDetailsPage')
);
const JobApplicantsListPage = lazy(() =>
	import('../pages/JobApplicantsListPage/JobApplicantsListPage')
);

const JobDescriptionDetailsPage = lazy(() =>
	import('../pages/job-description-details-page/JobDescriptionDetails')
);
const JobListPage = lazy(() => import('../pages/job-list/JobList'));
const ManageTeamPage = lazy(() => import('../pages/manage-team-page/ManageTeamPage'));
const NotFoundPage = lazy(() => import('../pages/not-found-page/NotFoundPage'));
const PolicyPage = lazy(() => import('../pages/_Policy/PolicyPage'));
const PublicCreateJobForm = lazy(() => import('../pages/PublicCreateJobForm'));
const PublicJobDetailsPage = lazy(() =>
	import('../pages/public-job-details-page/PublicJobDetailsPage')
);
const PublicJobsListPage = lazy(() => import('../pages/public-jobs-list-page/PublicJobsListPage'));
const SettingsPage = lazy(() => import('../pages/settings-page/SettingsPage'));
const SignUpConfirmation = lazy(() => import('../pages/signUpConfirmation/SignUpConfirmation'));
const SignUpPage = lazy(() => import('../pages/signup-page/SignUpPage'));
const SingleJobApplicantsListPage = lazy(() =>
	import('../pages/single-job-applicant-list-page/SingleJobApplicantListPage')
);
const SubscribedPage = lazy(() => import('../pages/Subscribed/SubscribedPage'));
const SubscriptionPlanPage = lazy(() => import('../pages/SubscriptionPlan/SubscriptionPlanPage'));
const TalentPoolApplicantListPage = lazy(() =>
	import('../pages/talent-pool-applicant-list-page/TalentPoolApplicantListPage')
);
const TalentPoolJobListPage = lazy(() =>
	import('../pages/talent-pool-job-list-page/TalentPoolJobListPage')
);
const TermsConditionsPage = lazy(() => import('../pages/_Terms&Conditions/Terms&ConditionsPage'));
const UpdateAPoolPage = lazy(() => import('../pages/update-a-pool-page/UpdateAPoolPage'));

const routes = [
	{
		name: 'AddApplicantPage',
		component: AddApplicantPage,
		path: '/add-applicant/:id',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'ChangePassword',
		component: ChangePassword,
		path: '/change-password',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'ChangePasswordPage',
		component: ChangePasswordPage,
		path: '/profile/change-password',
		exact: true,
		roles: [ADMIN],
		protected: true,
		public: false,
	},
	// {
	// 	name: 'ContactSales',
	// 	component: ContactSalesTeamPage,
	// 	path: '/contact-sales',
	// 	exact: true,
	// 	roles: [],
	// 	protected: false,
	// 	public: true,
	// },
	{
		name: 'CreateAPool',
		component: CreateAPool,
		path: '/create-a-pool',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'CreateJobPost',
		component: CreateJobPost,
		path: '/create-new-job',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	// {
	// 	name: 'DashboardPage',
	// 	component: DashboardPage,
	// 	path: '/dashboard',
	// 	exact: true,
	// 	roles: [],
	// 	protected: true,
	// 	public: false,
	// },
	{
		name: 'NewDashboard',
		component: NewDashboardPage,
		path: '/dashboard',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	// {
	// 	name: 'EditApplicantPage',
	// 	component: EditApplicantPage,
	// 	path: '/edit-applicant/:id',
	// 	exact: true,
	// 	roles: [],
	// 	protected: true,
	// 	public: false,
	// },
	{
		name: 'EditCompanyInfo',
		component: EditCompanyInfo,
		path: '/profile/edit',
		exact: true,
		roles: [ADMIN],
		protected: true,
		public: false,
	},
	{
		name: 'EditJobDetails',
		component: EditJobDetails,
		path: '/jobs/edit/:id',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'ForgetPasswordEmailPage',
		component: ForgetPasswordEmailPage,
		path: '/forgetPasswordEmailPage',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'ForgotPasswordOTPPage',
		component: ForgotPasswordOTPPage,
		path: '/signup/forgot',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'ForgotPasswordPage',
		component: ForgotPasswordPage,
		path: '/forgot-password',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	// {
	// 	name: 'HomePage',
	// 	component: HomePage,
	// 	path: '/',
	// 	exact: true,
	// 	roles: [],
	// 	protected: false,
	// 	public: true,
	// },
	{
		name: 'InvitationSignUpPage',
		component: InvitationSignUpPage,
		path: '/invite/signup',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'JobApplicantDetailsFromAllJob',
		component: JobApplicantDetailsFromAllJob,
		path: '/jobs/applicants-profile/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobApplicantDetailsFromPool',
		component: JobApplicantDetailsFromPool,
		path: '/talent-pool-job/applicants/applicants-profile/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobApplicantDetailsPage',
		component: JobApplicantDetailsPage,
		path: '/jobs/applicants/applicants-profile/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobApplicantsListPage',
		component: JobApplicantsListPage,
		path: '/jobs/applicants',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobApplicants List Page',
		component: SingleJobApplicantsListPage,
		path: '/jobs/applicants/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobDescriptionDetails',
		component: JobDescriptionDetailsPage,
		path: '/jobs/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'JobListPage',
		component: JobListPage,
		path: '/jobs',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'LoginPage',
		component: LoginPage,
		path: '/signin',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'HomePage',
		component: LoginPage,
		path: '/',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'ManageTeamPage',
		component: ManageTeamPage,
		path: '/manage-team',
		exact: true,
		roles: [ADMIN],
		protected: true,
		public: false,
	},
	{
		name: 'NewPasswordPage',
		component: NewPasswordPage,
		path: '/save-new-password',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'PolicyPage',
		component: PolicyPage,
		path: '/policyPage',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'PublicCreateJobForm',
		component: PublicCreateJobForm,
		path: '/open/feature-job/:id',
		exact: true,
		roles: [],
	},
	{
		name: 'PublicJobDetailsCompanyPage',
		component: PublicJobDetailsCompanyPage,
		path: '/open/job/:company/:job',
		exact: true,
		roles: [],
	},
	{
		name: 'PublicJobDetailsPage',
		component: PublicJobDetailsPage,
		path: '/open/job/:id',
		exact: true,
		roles: [],
	},
	{
		name: 'PublicJobsListPage',
		component: PublicJobsListPage,
		path: '/open/jobs/:id',
		exact: true,
		roles: [],
	},
	{
		name: 'SettingsPage',
		component: SettingsPage,
		path: '/settings',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'SignUpConfirmation',
		component: SignUpConfirmation,
		path: '/signup/confirmation',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'SignUpPage',
		component: SignUpPage,
		path: '/signup',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'SubscribedPage',
		component: SubscribedPage,
		path: '/subscription-plans/subscribed',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'SubscriptionPlanPage',
		component: SubscriptionPlanPage,
		path: '/subscription-plans',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'TalentPoolApplicantListPage',
		component: TalentPoolApplicantListPage,
		path: '/talent-pool-job/applicants/:id',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'TalentPoolJobListPage',
		component: TalentPoolJobListPage,
		path: '/talent-pool-job',
		exact: true,
		roles: [ADMIN, MANAGER, ASSISTANT],
		protected: true,
		public: false,
	},
	{
		name: 'TermsConditionsPage',
		component: TermsConditionsPage,
		path: '/termsConditions',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'UpdateAPoolPage',
		component: UpdateAPoolPage,
		path: '/update-a-pool/:id',
		exact: true,
		roles: [ADMIN, MANAGER],
		protected: true,
		public: false,
	},
	{
		name: 'NotFoundPage',
		component: NotFoundPage,
		path: '*',
		exact: true,
		roles: [],
		protected: false,
		public: true,
	},
	{
		name: 'NotFoundPage',
		component: NotFoundPage,
		path: '/page-not-found',
		exact: true,
		roles: [],
	},
];

export default routes;
