const Facebook = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
			<path
				fill="#1778F2"
				d="M16 0C7.163 0 0 7.218 0 16.122 0 24.078 5.72 30.682 13.24 32v-.055h.001V20.57H9.38v-4.45h3.862v-3.55c0-4.04 2.647-6.454 6.303-6.454 1.75 0 3.628.29 3.628.29v4.157h-2.065c-1.987 0-2.348 1.26-2.348 2.535v3.023h4.413l-.771 4.449h-3.642V32C26.279 30.682 32 24.078 32 16.122 32 7.218 24.837 0 16 0z"
			/>
		</svg>
	);
};

export default Facebook;
