const CloseModal = () => {
	return (
		<svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.31128 23.5684L24.3115 6.56818"
				stroke="#02060C"
				strokeWidth="2.57827"
				strokeLinejoin="round"
			/>
			<path
				d="M24.3115 23.5685L7.31128 6.56836"
				stroke="#02060C"
				strokeWidth="2.57827"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CloseModal;
