/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export const TalentPoolSlider = ({
	handleConfirmPoolModal = null,
	poolJobListData = {},
	children,
}) => {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		draggable: true,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return <Slider {...settings}>{children}</Slider>;
};
