const LinkedIn = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
			<g clipPath="url(#clip0_543_17672)">
				<path
					fill="#007AB9"
					d="M16 32c8.837 0 16-7.164 16-16 0-8.837-7.163-16-16-16S0 7.163 0 16c0 8.836 7.163 16 16 16z"
				/>
				<path
					fill="#F1F2F2"
					d="M25.56 17.287v6.596h-3.825V17.73c0-1.545-.552-2.6-1.936-2.6-1.057 0-1.685.71-1.962 1.398-.1.245-.127.587-.127.932v6.424h-3.825s.052-10.423 0-11.502h3.825v1.63l-.025.037h.025v-.037c.509-.782 1.415-1.9 3.447-1.9 2.516 0 4.403 1.643 4.403 5.176zM9.884 6.836c-1.308 0-2.164.859-2.164 1.987 0 1.104.831 1.988 2.114 1.988h.025c1.334 0 2.163-.884 2.163-1.988-.025-1.128-.829-1.987-2.138-1.987zM7.948 23.883h3.823V12.381H7.948v11.502z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_543_17672">
					<path fill="#fff" d="M0 0H32V32H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LinkedIn;
