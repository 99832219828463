const StatArrowUp = ({ className }) => {
	return (
		<svg
			width="18"
			height="10"
			viewBox="0 0 18 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M6.97112 4.68846C7.00956 4.65251 7.06929 4.65251 7.10773 4.68846L10.6246 7.97746C10.6631 8.01341 10.7228 8.01341 10.7613 7.97746L15.8373 3.23036C15.8758 3.19441 15.9355 3.19441 15.9739 3.23036L17.8317 4.96774C17.8956 5.0275 18 4.98219 18 4.8947V0.0999999C18 0.0447714 17.9552 0 17.9 0H12.773C12.6823 0 12.6385 0.111076 12.7047 0.173038L14.536 1.88562C14.5783 1.92514 14.5783 1.99217 14.536 2.03169L10.7613 5.56182C10.7228 5.59777 10.6631 5.59777 10.6246 5.56182L7.10773 2.27281C7.06929 2.23686 7.00956 2.23686 6.97112 2.27281L0.078099 8.71914C0.0358383 8.75866 0.0358381 8.82569 0.0780988 8.86521L1.22322 9.93612C1.26166 9.97207 1.32138 9.97207 1.35983 9.93612L6.97112 4.68846Z"
				fill="#448569"
			/>
		</svg>
	);
};

export default StatArrowUp;
