/* eslint-disable object-shorthand */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { ErrorMessage, Field, Form, Formik } from 'formik';
import CrossAttach from '../../assets/svgs/CrossAttach';
import RightArrow from '../../assets/svgs/RightArrow';
import { Step1Schema } from '../../utils/multistepFormValidationSchemas';

const Step1Form = ({ initialValues, onSubmit, handleFileUpload, fileState, setFileState }) => {
	return (
		<Formik initialValues={initialValues} validationSchema={Step1Schema} onSubmit={onSubmit}>
			{({ setFieldValue, errors, touched }) => (
				<Form>
					<div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2">
						<div>
							<Field
								type="text"
								name="firstName"
								placeholder="First Name*"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="firstName" component="div" className="mt-1 text-sm text-red" />
						</div>

						<div>
							<Field
								type="text"
								name="lastName"
								placeholder="Last name*"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="lastName" component="div" className="mt-1 text-sm text-red" />
						</div>

						<div>
							<Field
								type="email"
								name="email"
								placeholder="Email*"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="email" component="div" className="mt-1 text-sm text-red" />
						</div>

						<div>
							<Field
								type="text"
								name="mobile"
								placeholder="Mobile number*"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="mobile" component="div" className="mt-1 text-sm text-red" />
						</div>

						<div>
							<Field
								type="text"
								name="linkedinUrl"
								placeholder="LinkedIn URL"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="linkedinUrl" component="div" className="mt-1 text-sm text-red" />
						</div>

						<div>
							<Field
								type="text"
								name="websiteUrl"
								placeholder="Website (ex: GitHub, GitLab, etc.)"
								className="w-full rounded-lg border border-gray-300 p-3"
							/>
							<ErrorMessage name="websiteUrl" component="div" className="mt-1 text-sm text-red" />
						</div>
					</div>
					<div className="mb-6">
						<label className="flex w-fit cursor-pointer items-center gap-2">
							<span className="font-semibold">Resume/CV*</span>
							<span className="font-semibold text-blue-500">Attach</span>
							<input
								type="file"
								accept=".pdf"
								key={fileState.fileName || 'file-input'}
								onChange={async (event) => {
									const file = event.currentTarget.files[0];
									if (file && !file.name.toLowerCase().endsWith('.pdf')) {
										setFileState({
											file: null,
											fileName: '',
											error: 'Please upload a PDF file',
										});
										setFieldValue('resume', null);
										return;
									}

									setFileState({
										file: file,
										fileName: file ? file.name : '',
										error: '',
									});
									setFieldValue('resume', file);
									await handleFileUpload(file, setFieldValue);
								}}
								className="hidden"
							/>
						</label>
						{fileState.fileName && (
							<div className="mt-2 flex w-fit items-center gap-3 rounded-md border border-gray-200 p-2">
								<p className="text-sm text-gray-600">{fileState.fileName}</p>
								<button
									type="button"
									onClick={() => {
										setFileState({
											file: null,
											fileName: '',
											error: '',
										});
										setFieldValue('resume', null);
									}}
									className="ml-2 text-gray-500 hover:text-gray-700"
								>
									<CrossAttach />
								</button>
							</div>
						)}
						{fileState.error && <div className="mt-1 text-sm text-red">{fileState.error}</div>}
						{errors.resume && touched.resume && (
							<div className="mt-1 text-sm text-red">{errors.resume}</div>
						)}
					</div>

					<div className="flex justify-end">
						<button
							type="submit"
							className="flex items-center gap-2 rounded-lg bg-black px-6 py-3 text-white"
						>
							Next <RightArrow />
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default Step1Form;
