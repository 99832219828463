/* eslint-disable prettier/prettier */
/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Leafs from '../../assets/images/leafs.png';

export function ApplicantsBanner({ buttonText = '', buttonLink="#", heading = '', para = '' }) {
	const [showBanner, setShowBanner] = useState(true);

	useEffect(() => {
		const isBannerHidden = localStorage.getItem('isApplicantsBannerHidden');
		setShowBanner(!isBannerHidden);
	}, []);

	const handleCloseBanner = () => {
		setShowBanner(false);
		localStorage.setItem('isApplicantsBannerHidden', 'true');
	};

	return (
		<div className={`relative mb-8 w-full rounded-lg bg-amber-400 p-8 ${!showBanner && 'hidden'}`}>
			{/* Close button */}
			<button
				className="absolute right-4 top-4 hover:opacity-70"
				aria-label="Close banner"
				onClick={handleCloseBanner}
			>
				<svg
					width="13"
					height="13"
					viewBox="0 0 13 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M10.8132 0.914452C10.7742 0.875403 10.7108 0.875399 10.6718 0.914444L6.57022 5.01506C6.53116 5.0541 6.46785 5.0541 6.4288 5.01505L2.32822 0.914461C2.28916 0.875408 2.22585 0.875408 2.18679 0.914461L0.914216 2.18704C0.875164 2.22609 0.875164 2.28941 0.914217 2.32846L5.01479 6.42904C5.05385 6.46809 5.05385 6.53141 5.01479 6.57046L0.914216 10.671C0.875164 10.7101 0.875164 10.7734 0.914217 10.8125L2.18679 12.085C2.22585 12.1241 2.28916 12.1241 2.32822 12.085L6.4288 7.98445C6.46785 7.9454 6.53116 7.9454 6.57022 7.98444L10.6718 12.0851C10.7108 12.1241 10.7742 12.1241 10.8132 12.085L12.0858 10.8125C12.1248 10.7734 12.1248 10.7101 12.0858 10.671L7.98522 6.57046C7.94616 6.53141 7.94616 6.46809 7.98522 6.42904L12.0858 2.32846C12.1248 2.28941 12.1248 2.22609 12.0858 2.18704L10.8132 0.914452Z"
						fill="#777672"
					/>
				</svg>
			</button>

			{/* Decorative leaf */}
			<img
				src={Leafs}
				alt="Leafs"
				className="max-h-50 absolute right-6 top-0 h-44 w-40"
				aria-hidden="true"
			/>

			{/* Content */}
			<div className="max-w-6xl">
				<h2 className="mb-4 text-3xl font-bold text-black">{heading}</h2>
				<p className="mb-6 max-w-5xl text-lg text-black">{para}</p>
				<Link
					to={buttonLink}
					className="rounded-md bg-black px-6 py-3 font-semibold text-white transition-colors hover:bg-opacity-80"
				>
					{buttonText}
				</Link>
			</div>
		</div>
	);
}
