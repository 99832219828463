/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import StatArrowDown from '../../assets/svgs/StatArrowDown';
import StatArrowUp from '../../assets/svgs/StatArrowUp';

const cardsData = [
	{
		title: 'Total Applicants',
		value: '1025',
		change: '23',
		isIncrease: true,
		color: 'black',
	},
	{
		title: 'Total Job posted',
		value: '12',
		change: '8',
		isIncrease: false,
		color: 'black',
	},
	{
		title: 'Good Fit Applicants',
		value: '654',
		change: '2',
		isIncrease: true,
		color: 'green',
	},
	{
		title: 'Maybe Fit Applicants',
		value: '654',
		change: '2',
		isIncrease: true,
		color: 'yellow',
	},
	{
		title: 'Not Fit Applicants',
		value: '340',
		change: '5',
		suffix: 'vs last month',
		isIncrease: true,
		color: 'red',
	},
];

const Card = ({ title, value, change, isIncrease, color, suffix }) => {
	const getTextColor = () => {
		switch (color) {
			case 'green':
				return 'text-emerald-500';
			case 'yellow':
				return 'text-yellow-500';
			case 'red':
				return 'text-red';
			default:
				return 'text-gray-900';
		}
	};

	return (
		<div className="cursor-pointer rounded-lg bg-[#F7F9FD] p-6 shadow-sm hover:bg-[#F4FAFF] hover:shadow-md">
			<h3 className={`mb-4 text-sm font-medium ${getTextColor()}`}>{title}</h3>
			<div className="flex flex-col items-baseline gap-2">
				<span className="text-4xl font-semibold text-gray-900">{value}</span>
				<div className="flex items-center gap-1">
					{isIncrease ? (
						<StatArrowUp className="h-6 w-6 text-emerald-500" />
					) : (
						<StatArrowDown className="text-red-500 h-6 w-6" />
					)}
					<span className={`text-md ${isIncrease ? 'text-emerald-500' : 'text-red'}`}>
						{change}%{suffix && ` ${suffix}`}
					</span>
				</div>
			</div>
		</div>
	);
};

export default function StatisticsCards() {
	return (
		<div className="">
			<div className="grid grid-cols-1 gap-4 md:grid-cols-5">
				{cardsData.map((card, index) => (
					<Card key={index} {...card} />
				))}
			</div>
		</div>
	);
}
