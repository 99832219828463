import DeleteJob from '../assets/svgs/DeleteJob';
import ShareJob from '../assets/svgs/ShareJob';
import UpdateJob from '../assets/svgs/UpdateJob';

export const DropDownData = [
	{
		title: 'Share Job',
		icon: <ShareJob />,
	},
	{
		title: 'Update Job',
		icon: <UpdateJob />,
	},
	{
		title: 'Delete Job',
		icon: <DeleteJob />,
	},
];
