const StatArrowDown = ({ className }) => {
	return (
		<svg
			width="19"
			height="10"
			viewBox="0 0 19 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M10.9612 2.02254C10.9228 1.98659 10.863 1.98659 10.8246 2.02254L7.30768 5.31154C7.26924 5.34749 7.20951 5.34749 7.17107 5.31154L1.55978 0.0638781C1.52134 0.0279283 1.46161 0.0279282 1.42317 0.063878L0.27805 1.13479C0.235789 1.17431 0.235789 1.24134 0.27805 1.28086L7.17107 7.72719C7.20952 7.76314 7.26924 7.76314 7.30768 7.72719L10.8246 4.43818C10.863 4.40223 10.9228 4.40223 10.9612 4.43818L14.7359 7.96831C14.7782 8.00783 14.7782 8.07486 14.7359 8.11438L12.9047 9.82696C12.8384 9.88892 12.8823 10 12.973 10H18.1C18.1552 10 18.2 9.95523 18.2 9.9V5.1053C18.2 5.01781 18.0955 4.9725 18.0316 5.03226L16.1739 6.76964C16.1354 6.80559 16.0757 6.80559 16.0373 6.76964L10.9612 2.02254Z"
				fill="#854444"
			/>
		</svg>
	);
};

export default StatArrowDown;
