import { Helmet } from 'react-helmet-async';

import { appUrl } from '../../apis/config/baseURL';
import openGraphImage from '../../assets/images/open-graph-image.png';
import SignUpConfirmation from '../../assets/images/signUpConfirmation.png';
import LoginForm from '../../components/organisms/LoginForm';

const LoginPage = () => {
	return (
		<>
			<Helmet>
				<link rel="canonical" href={`${appUrl}/signin`} />
				<title>Sign in - HrClerks ATS</title>
				<meta
					name="description"
					content="Unlock powerful features and take control of your hiring journey. Join the revolution in talent acquisition today!"
				/>
				{/* Open Graph */}
				<meta property="article:author" content="Sign up for free - HrClerks ATS" />
				<meta property="article:publisher" content={appUrl} />
				<meta property="og:site_name" content="hrclerks.com" />
				<meta name="og:title" content="Sign in - HrClerks ATS" />
				<meta
					name="og:description"
					content="Unlock powerful features and take control of your hiring journey. Join the revolution in talent acquisition today!"
				/>
				<meta name="og:image" content={openGraphImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="og:type" content="website" />
				<meta name="og:url" content={`${appUrl}/signin`} />
				{/* Twitter */}
				<meta property="article:author" content="Sign up for free - HrClerks ATS" />
				<meta property="article:publisher" content={appUrl} />
				<meta property="twitter:site_name" content="hrclerks.com" />
				<meta name="twitter:title" content="Sign in - HrClerks ATS" />
				<meta
					name="twitter:description"
					content="Unlock powerful features and take control of your hiring journey. Join the revolution in talent acquisition today!"
				/>
				<meta name="twitter:image" content={openGraphImage} />
				<meta property="twitter:image:width" content="1200" />
				<meta property="twitter:image:height" content="630" />
				<meta name="twitter:url" content={`${appUrl}/signin`} />
				<meta name="twitter:card" content="Sign in - HrClerks ATS" />
			</Helmet>

			<div className="fixed left-0 h-screen w-full md:flex">
				<img
					className="hidden h-full w-[45%] object-cover md:block"
					src={SignUpConfirmation}
					alt=""
				/>
				<div className="mx-auto flex h-full w-full flex-col justify-center bg-white text-center sm:px-0">
					<LoginForm />
				</div>
			</div>
		</>
	);
};

export default LoginPage;
