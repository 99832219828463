const StartIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" fill="none" viewBox="0 0 15 14">
			<path
				fill="#FFD831"
				d="M7.314 0a.865.865 0 00-.784.487l-1.786 3.62-3.995.58A.875.875 0 00.265 6.18l2.89 2.816-.683 3.979a.875.875 0 001.269.921l3.573-1.876V0z"
			/>
			<path
				fill="#FFD831"
				d="M7.269 0c.311 0 .623.161.784.487l1.786 3.62 3.995.58a.875.875 0 01.484 1.493l-2.89 2.816.683 3.979a.875.875 0 01-1.269.921L7.27 12.02V0z"
			/>
		</svg>
	);
};

export default StartIcon;
