/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */

import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { submitApplication, uploadFile } from '../../apis/applicationAPIs';
import CloseModal from '../../assets/svgs/CloseModal';
import Step1Form from '../organisms/Step1Form';
import Step2Form from '../organisms/Step2Form';
import Step3Form from '../organisms/Step3Form';

const MultiStepForm = ({ isOpen, onClose, job = {} }) => {
	const [submitted, setSubmitted] = useState(false);
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({});
	const [fileState, setFileState] = useState({
		file: null,
		fileName: '',
		error: '',
	});

	const handleClose = () => {
		onClose();
		setFormData({});
		setStep(1);
	};

	const { mutateAsync, isPending, isSuccess } = useMutation({
		mutationFn: submitApplication,
		// eslint-disable-next-line no-unused-vars
		onSuccess: (data) => {
			if (data?.status === 200 || data?.status === 201) {
				toast.success('Thanks. Your application successfully submitted.');
				handleClose();
				setSubmitted(true);

				setTimeout(() => {
					window.location.reload();
				}, 2000);
				setSubmitted(true);
				handleClose();
			}

			if (data?.response?.status === 403) {
				toast.error(data?.response?.data?.message);
			}
		},
	});

	const { mutateAsync: uploadFileMutation } = useMutation({
		mutationFn: uploadFile,
		onSuccess: (fileData) => {
			setFormData((prev) => ({
				...prev,
				resumeUrl: fileData.data.url,
				resumeFile: prev.resume,
			}));
		},
	});

	const handleFileUpload = async (file, setFieldValue) => {
		if (file) {
			const fileFormData = new FormData();
			fileFormData.append('file', file);
			try {
				const result = await uploadFileMutation(fileFormData);
				setFieldValue('resumeUrl', result.data.url);
			} catch (error) {
				console.error('File upload failed:', error);
				toast.error('File upload failed:', error);
			}
		}
	};

	const handleSubmit = async (values) => {
		if (step === 1) {
			setFormData({ ...formData, ...values });
			setStep(2);
		} else if (step === 2) {
			setFormData({ ...formData, ...values });
			setStep(3);
		} else {
			const formattedData = {
				jobId: job?.id,
				firstName: formData.firstName,
				lastName: formData.lastName,
				email: formData.email,
				phone: formData.mobile,
				linkedin: formData.linkedinUrl,
				profile: `https://api.dicebear.com/9.x/dylan/svg?seed=${`${formData.firstName} ${formData.lastName}`}&backgroundType=gradientLinear&radius=50&size=64`,
				resume: formData.resumeUrl,
				rating: 5,
				presentAddress: '',
				parmanentAddress: '',
				questionAboutUs: '',
				whyInterested: '',
				answers: [formData.dynamicQuestions],
			};

			const response = await mutateAsync(formattedData);
			if (response?.status === 201 || response?.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 2000);
				setSubmitted(true);
				onClose();
				setFormData({});
				setStep(1);
			}

			if (response?.status === 403 || response?.status === 404) {
				if (response?.data?.message) {
					toast.error(response?.data?.message);
				} else {
					toast.error('Could not process your request. Please try again.');
				}
			}
		}
		setSubmitted(false);
	};

	const handleBack = () => {
		setStep(step - 1);

		// Restoring fileState when going back to Step 1
		if (step === 2) {
			setFileState({
				file: formData.resume,
				fileName: formData.resume ? formData.resume.name : '',
				error: '',
			});
		}
	};

	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
			<div className="relative max-h-[90vh] w-11/12 max-w-3xl overflow-y-auto rounded-lg bg-white px-8 pb-8 pt-16">
				<button className="absolute right-4 top-4 text-2xl font-bold" onClick={onClose}>
					<CloseModal />
				</button>

				<div className="mb-8 flex justify-between space-x-6">
					<div className={`h-2 flex-1 rounded-3xl ${step >= 1 ? 'bg-black' : 'bg-gray-300'} `} />
					<div className={`h-2 flex-1 rounded-3xl ${step >= 2 ? 'bg-black' : 'bg-gray-300'} `} />
					<div className={`h-2 flex-1 rounded-3xl ${step >= 3 ? 'bg-black' : 'bg-gray-300'} `} />
				</div>

				{step === 1 && (
					<div>
						<h2 className="mb-6 text-2xl font-bold">Apply for this Job</h2>
						<Step1Form
							initialValues={{
								firstName: formData.firstName || '',
								lastName: formData.lastName || '',
								email: formData.email || '',
								mobile: formData.mobile || '',
								linkedinUrl: formData.linkedinUrl || '',
								websiteUrl: formData.websiteUrl || '',
								resume: formData.resume || null,
								resumeFile: formData.resumeFile || null,
								resumeUrl: formData.resumeUrl || '',
							}}
							onSubmit={handleSubmit}
							handleFileUpload={handleFileUpload}
							fileState={fileState}
							setFileState={setFileState}
						/>
					</div>
				)}

				{step === 2 && (
					<div>
						<h2 className="mb-6 text-2xl font-bold">Answer screening questions</h2>
						<Step2Form
							initialValues={{
								...(job?.questions || []).reduce((acc, _, index) => {
									acc[`question_${index}`] = formData[`question_${index}`] || '';
									return acc;
								}, {}),
							}}
							onSubmit={(values) => {
								const transformedValues = {
									...values,
									dynamicQuestions:
										job?.questions?.map((q, index) => ({
											question: q.question,
											answer: values[`question_${index}`],
										})) || [],
								};
								handleSubmit(transformedValues);
							}}
							handleBack={handleBack}
							questions={job?.questions}
						/>
					</div>
				)}

				{step === 3 && (
					<Step3Form formData={formData} onSubmit={handleSubmit} handleBack={handleBack} />
				)}
			</div>
		</div>
	);
};

export default MultiStepForm;
