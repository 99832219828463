/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { RxDotFilled } from 'react-icons/rx';
import RightArrow from '../../assets/svgs/RightArrow';
import { createStep2Schema } from '../../utils/multistepFormValidationSchemas';

const Step2Form = ({ initialValues, onSubmit, handleBack, questions }) => (
	<Formik
		initialValues={initialValues}
		validationSchema={createStep2Schema(questions)}
		onSubmit={onSubmit}
	>
		<Form>
			<div className="space-y-6">
				{questions?.map((question, index) => (
					<div key={index}>
						<label className="mb-2 flex items-baseline gap-1 font-medium">
							<RxDotFilled />
							{question.question}*{' '}
						</label>
						<Field
							as="textarea"
							name={`question_${index}`}
							className="min-h-[60px] w-full rounded-lg border border-gray-300 p-3"
						/>
						<ErrorMessage
							name={`question_${index}`}
							component="div"
							className="mt-1 text-sm text-red"
						/>
					</div>
				))}
			</div>

			<div className="mt-8 flex justify-between">
				<button
					type="button"
					onClick={handleBack}
					className="rounded-lg border border-black px-6 py-3"
				>
					Back
				</button>
				<button
					type="submit"
					className="flex items-center gap-2 rounded-lg bg-black px-6 py-3 text-white"
				>
					Next <RightArrow />
				</button>
			</div>
		</Form>
	</Formik>
);

export default Step2Form;
