const RightArrow = () => {
	return (
		<svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.73205 10.1602L11.4919 5.43332M11.4919 5.43332L6.73205 0.706482M11.4919 5.43332L1.17884 5.43332"
				stroke="white"
				strokeWidth="1.28914"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default RightArrow;
