/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useMutation } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { fetchTokensOrSignIn } from '../../apis/authAPIs';
import EyeClosed from '../../assets/svgs/EyeClosed';
import EyeOpen from '../../assets/svgs/EyeOpen';
import useAuth from '../../hooks/useAuth';
import useCurrentUserRole from '../../hooks/useCurrentUserRole';
import { loginInitialValues } from '../../pages/login-page/validation/loginInitialValues';
import { loginValidationSchema } from '../../pages/login-page/validation/loginValidationSchema';
import { setAuthToken } from '../../reducers/authReducer';
import { setCurrentUserRole } from '../../reducers/userRoleReducer';
import Button from '../atoms/Button';
import CheckBox from '../atoms/CheckBox';
import CustomInputField from '../atoms/CustomInputField';
import Error from '../atoms/Error';
import Typography from '../atoms/Typography';
import Container from '../molecules/Container';
import LanguageBtn from '../molecules/LanguageBtn';
import LoginFooter from '../molecules/LoginFooter';
import LoginFormHeader from '../molecules/LoginFormHeader';

const LoginForm = () => {
	const navigate = useNavigate();
	const [isEyeClosed, setIsEyeClosed] = useState(false);
	const { t } = useTranslation();

	const {
		mutateAsync: postSignIn,
		isError,
		error,
		isPending,
	} = useMutation({
		mutationFn: (data) => fetchTokensOrSignIn(data),
	});

	const { dispatch: authDispatch } = useAuth();
	const { dispatch: userRoleDispatch } = useCurrentUserRole();

	const handleEyeClosed = () => {
		setIsEyeClosed(!isEyeClosed);
	};

	const handleSubmit = async (values, { setSubmitting }) => {
		try {
			const response = await postSignIn(values);

			if (response?.success && response?.status === 200) {
				// eslint-disable-next-line no-unsafe-optional-chaining
				const { accessToken, refreshToken, role, name } = response?.data;

				if (accessToken && refreshToken) {
					// Store username in localStorage
					localStorage.setItem('username', name);

					authDispatch(setAuthToken(accessToken));
					userRoleDispatch(setCurrentUserRole(role));

					toast.success('Successfully signed in');
					navigate('/jobs');
					window.location.reload();
				}
			}
		} catch (signinError) {
			setSubmitting(false);
		}
	};

	return (
		<section className="relative flex flex-col">
			<LanguageBtn
				className="absolute end-5 top-5 sm:end-12 sm:top-12"
				language={localStorage.getItem('Language')}
			/>

			<div className="flex h-screen flex-col items-center justify-center ">
				<LoginFormHeader />

				<Formik
					initialValues={loginInitialValues}
					validationSchema={loginValidationSchema}
					onSubmit={handleSubmit}
				>
					<Form>
						<div className="mt-5 w-full rounded-lg sm:w-[410px]">
							{isError && <Error error={error} />}

							<CustomInputField
								name="email"
								className="focus:border-1 h-[48px] w-full appearance-none rounded border border-transparent px-3 py-4 leading-tight text-gray-700 shadow-md"
								type="email"
								placeholder={t('Email')}
							/>
						</div>

						<div className="mt-5 flex w-full flex-row items-center rounded-lg sm:w-[410px]">
							<div className="relative w-full">
								<CustomInputField
									name="password"
									className=" focus:border-1 w-full scroll-py-40 appearance-none rounded border border-transparent px-3 py-4 leading-tight text-gray-700 shadow-md"
									type={isEyeClosed ? 'text' : 'password'}
									placeholder={t('Password')}
								/>
								<div onClick={handleEyeClosed} className="absolute right-6 top-5 cursor-pointer">
									{isEyeClosed ? <EyeOpen /> : <EyeClosed />}
								</div>
							</div>
						</div>

						<Container className="mt-4 flex w-full flex-row items-center gap-5 sm:w-[410px]">
							<div className="flex items-center gap-1">
								<CheckBox isChecked={null} handleChecked={null} />
								<Typography
									variant="h4"
									className="text-center font-Montserrat text-sm font-normal leading-[21.94px] text-[#5F6D83] sm:text-base"
									title={t('Keep me logged in')}
								/>
							</div>
							<Link
								className="text-semibold ml-auto font-Montserrat font-[14px] leading-[21.94px] text-[#1098FF]"
								to="/forgetPasswordEmailPage"
							>
								<Typography
									className="text-sm sm:text-base"
									variant="h6"
									title={t('Forgot password?')}
								/>
							</Link>
						</Container>

						<Button
							disabled={isPending}
							type="submit"
							className="mt-[36.5px] h-[44px] w-full cursor-pointer rounded-lg bg-[#1098FF] font-Montserrat text-white outline-none sm:w-[410px]"
						/>
					</Form>
				</Formik>
				<LoginFooter />
			</div>
		</section>
	);
};

export default LoginForm;
