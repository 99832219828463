import { format } from 'date-fns';

export const dateConverter = (date = '') => {
	const parsedDate = new Date(date);
	const formattedDate = format(parsedDate, 'dd MMM yyyy');
	return formattedDate;
};

export const localFormateDate = (d) => {
	const date = new Date(d);

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();

	const formattedDate = `${months[month]} ${day} ${year}`;

	return formattedDate;
};
