import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { dateConverter } from '../../utils/dateConverter';
import CustomDropdown from '../atoms/CustomDropdown';

const JobsCard = ({
	data = null,
	handleJobDelete = null,
	dropIcon = null,
	handleConfirmationModal,
	handleShareJobModal,
	handleCloseJob,
	fromDashboard = false,
}) => {
	const { t } = useTranslation();

	// Render the JobsCard component
	const jobsContent = data?.map((item) => {
		let borderColor = '';
		let dateStatus = '';

		if (!item?.published) {
			borderColor = 'border-t-[#FFD831]';
			dateStatus = 'Created on: ';
		}

		if (item?.expired) {
			borderColor = 'border-t-[#E15241]';
			dateStatus = 'Closed on: ';
		}

		return (
			<div
				key={item?.id}
				className={`relative rounded-lg border-2 bg-[#FBFCFD] p-3 min-[450px]:p-6 ${
					fromDashboard ? 'my-5 mr-5 shadow-sm' : 'drop-shadow-xl'
				}  ${item?.published && !item?.expired ? 'border-t-[#5AB180]' : borderColor}`}
			>
				<Link to={`/jobs/${item?.id}`}>
					<div style={{ borderTop: `2px solid ${item?.color}` }}>
						<h2 className="mb-4 line-clamp-1 break-words text-xl font-semibold max-[500px]:text-lg">
							{item?.title}
						</h2>

						<div className="text-[14px] font-semibold text-[#777777]">
							{t('Applicants')}:
							<span className="ml-2 text-[14px] font-semibold text-[#A6AFBD]">
								({item?.published && t('Applied')} {item?.applications?.length}
								{!item?.published && ` ${t('Application')}`})
							</span>
						</div>

						<div className="mt-3 flex justify-between">
							<p className="text-[14px] font-semibold text-[#A6AFBD]">{item?.location}</p>
							<p className="text-[14px] font-semibold text-[#A6AFBD]">{item?.jobType}</p>
						</div>

						<hr className="mb-2 mt-2 h-1 w-full bg-[#E8F5FC]" />

						<div className="mt-auto ">
							<div className="flex gap-2">
								<span className="font-semibold text-[#5F6D83]">
									{item?.published && !item?.expired ? t('Posted on: ') : t(dateStatus)}
								</span>

								<p className="font-semibold text-[#5F6D83]">
									{item?.expired ? dateConverter(item?.expiredAt) : dateConverter(item?.createdAt)}
								</p>
							</div>
						</div>
					</div>
				</Link>

				{item?.slug !== 'feature-jobs' && (
					<div className="absolute right-0 top-7">
						<CustomDropdown
							id={item?.id}
							dropIcon={dropIcon}
							handleJobDelete={handleJobDelete}
							handleConfirmationModal={handleConfirmationModal}
							handleShareJobModal={handleShareJobModal}
							published={item?.published}
							expired={item?.expired}
							handleCloseJob={handleCloseJob}
						/>
					</div>
				)}
			</div>
		);
	});

	if (fromDashboard) {
		return jobsContent;
	}

	return (
		<div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-y-10 xl:grid-cols-3">
			{jobsContent}
		</div>
	);
};

export default JobsCard;
