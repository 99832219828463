const ShareJob = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
			<rect
				width="23.5"
				height="23.5"
				x="0.25"
				y="0.25"
				stroke="#A6AFBD"
				strokeWidth="0.5"
				rx="3.75"
			/>
			<g clipPath="url(#clip0_541_17527)">
				<path
					fill="#1098FF"
					d="M15.5 12c-1.05 0-1.99.464-2.63 1.196l-2.222-1.367c.114-.312.185-.644.185-.996 0-.23-.033-.45-.083-.665l2.953-1.363c.428.522 1.07.862 1.797.862a2.334 2.334 0 10-2.333-2.334c0 .143.017.28.041.415l-2.95 1.362a2.905 2.905 0 00-2.341-1.193 2.916 2.916 0 100 5.833 2.9 2.9 0 002.121-.926l2.218 1.365A3.5 3.5 0 1015.5 12zm0-5.833a1.168 1.168 0 010 2.333 1.168 1.168 0 010-2.333zm-7.583 6.416c-.965 0-1.75-.785-1.75-1.75s.785-1.75 1.75-1.75 1.75.785 1.75 1.75-.785 1.75-1.75 1.75zm7.583 5.25a2.335 2.335 0 01-2.333-2.333 2.335 2.335 0 012.333-2.333 2.335 2.335 0 012.333 2.333 2.336 2.336 0 01-2.333 2.333z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_541_17527">
					<path fill="#fff" d="M0 0H14V14H0z" transform="translate(5 5)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default ShareJob;
