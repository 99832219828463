/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import SidebarBannerImage from '../../assets/images/sidebar-banner-image.png';
import CollapseNavigation from '../../assets/svgs/CollapsNavigation';
import KeepNavOpen from '../../assets/svgs/KeepNavOpen';
import SideBarTogglerSVG from '../../assets/svgs/SideBarTogglerSVG';
import useAuth from '../../hooks/useAuth';
import { sidebarMenuItems } from '../../routes/sidebar-routes';
import SidebarItem from './SidebarItem';

const Sidebar = ({ isOpen, narrowSidebar, setNarrowSidebar }) => {
	const { t } = useTranslation();
	const [activeItem, setActiveItem] = useState(sidebarMenuItems[0].id);
	const [showBanner, setShowBanner] = useState(true);

	const location = useLocation();
	const { state: hasAccessToken } = useAuth();

	useEffect(() => {
		const isBannerHidden = localStorage.getItem('isBannerHidden');
		setShowBanner(!isBannerHidden);
	}, []);

	const handleCloseBanner = () => {
		setShowBanner(false);
		localStorage.setItem('isBannerHidden', 'true');
	};

	const handleItemClick = (item) => {
		setActiveItem(item?.id);
	};

	return (
		<aside
			className={`${
				hasAccessToken && location.pathname !== '/' ? '' : ''
			} h-screen bg-white sm:flex sm:flex-shrink-0`}
		>
			{/* <div
				className={`flex h-[100%] flex-col justify-between transition-all ${
					isOpen ? 'w-[234px]' : 'w-0 overflow-hidden lg:w-[234px]'
				}`}
			> */}
			<div
				className={`flex h-[100%] flex-col justify-between transition-all ${
					isOpen ? 'w-[234px]' : `${narrowSidebar ? 'w-[60px] ' : 'w-[234px]'}`
				}`}
			>
				{/* Main menu items */}
				<div className="relative mx-2 mt-32 flex flex-col items-center gap-2">
					{sidebarMenuItems
						.filter((item) => item.title !== 'Help Center' && item.title !== 'Privacy Policy')
						.map((item) => (
							<SidebarItem
								item={item}
								key={item?.id}
								text={t(item?.title)}
								icon={item?.icon}
								path={item?.path}
								narrowSidebar={narrowSidebar}
								isActive={activeItem === item?.id}
								onClick={() => handleItemClick(item)}
							/>
						))}
				</div>

				{/* Bottom menu items */}
				<div className="mx-2 mb-2 mt-auto flex flex-col items-center gap-2">
					{showBanner && (
						<div className="relative">
							<Link to="/subscription-plans/subscribed">
								<img
									src={SidebarBannerImage}
									alt="Sidebar Banner Image"
									className="mb-2 mt-6 cursor-pointer"
								/>
							</Link>
							<button
								type="button"
								className="absolute right-0 top-6 p-1"
								onClick={(e) => {
									e.stopPropagation();
									handleCloseBanner();
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-4 w-4"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
						</div>
					)}
					{sidebarMenuItems
						.filter((item) => item.title === 'Help Center' || item.title === 'Privacy Policy')
						.map((item) => (
							<SidebarItem
								item={item}
								key={item?.id}
								text={t(item?.title)}
								icon={item?.icon}
								path={item?.path}
								narrowSidebar={narrowSidebar}
								isActive={activeItem === item?.id}
								onClick={() => handleItemClick(item)}
							/>
						))}
				</div>

				{/* <div className="h-[58px] w-full text-right">
					<SidebarBottomMenus />
				</div> */}
				<div className="group relative mb-6 ml-auto mr-2 border">
					<button
						type="button"
						label="Surname"
						onClick={() => setNarrowSidebar((oldState) => !oldState)}
						className="mb-6 ml-auto mr-1 border"
					>
						<SideBarTogglerSVG />
						{!narrowSidebar ? (
							<CollapseNavigation className="absolute -top-1 left-12 z-50 h-0 w-0 transition-all group-hover:h-fit group-hover:w-fit" />
						) : (
							<KeepNavOpen className="absolute -top-1 left-12 z-50 h-0 w-0 transition-all group-hover:h-fit group-hover:w-fit" />
						)}
					</button>
				</div>
			</div>
		</aside>
	);
};

export default Sidebar;
