const Twitter = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
			<g clipPath="url(#clip0_543_17601)">
				<path
					fill="#03A9F4"
					d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
				/>
				<path
					fill="#fff"
					d="M26.365 9.573c-.78.34-1.604.566-2.449.671a4.223 4.223 0 001.87-2.349 8.488 8.488 0 01-2.695 1.03 4.248 4.248 0 00-7.352 2.905c-.003.325.03.65.1.968a12.033 12.033 0 01-8.76-4.444 4.252 4.252 0 001.307 5.684 4.193 4.193 0 01-1.92-.524v.046a4.27 4.27 0 003.404 4.173 4.236 4.236 0 01-1.114.139c-.27.005-.54-.02-.806-.072a4.292 4.292 0 003.973 2.96 8.545 8.545 0 01-5.264 1.813 7.68 7.68 0 01-1.016-.06 11.956 11.956 0 006.526 1.908c7.82 0 12.097-6.478 12.097-12.092 0-.188 0-.369-.015-.55a8.456 8.456 0 002.114-2.206z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_543_17601">
					<path fill="#fff" d="M0 0H32V32H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Twitter;
