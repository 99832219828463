/* eslint-disable no-unused-vars */
const EmailShare = ({ width = '15', height = '12', className = '', style = {}, ...props }) => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_7096_25621)">
				<path
					d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
					fill="#65558F"
				/>
				<path
					d="M7 13.1738V20.4545C7 21.8604 8.13964 23 9.54546 23H22.2727C23.6785 23 24.8182 21.8604 24.8182 20.4545V13.1738L17.2432 17.8353C16.425 18.3388 15.3932 18.3388 14.575 17.8353L7 13.1738Z"
					fill="white"
				/>
				<path
					d="M24.8182 11.6794V11.5455C24.8182 10.1396 23.6785 9 22.2727 9H9.54545C8.13964 9 7 10.1396 7 11.5455V11.6794L15.2421 16.7514C15.6511 17.0031 16.1671 17.0031 16.5761 16.7514L24.8182 11.6794Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7096_25621">
					<rect width="32" height="32" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default EmailShare;
